import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as InstaSvg } from "../../assets/images/푸터_인스타그램.svg";
import { ReactComponent as BlogSvg } from "../../assets/images/푸터_네이버블로그.svg";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/푸터_로고.avif";
import { useNotReadyAlert } from "../../hooks/useNotReadyAlert";
import useWindowWidth from "../../hooks/useWindowWidth";
import { getAnalytics, logEvent } from "firebase/analytics";

function Footer() {
  const { t } = useTranslation();
  const [mobile, setMobile] = useState(false);
  const viewWidth = useWindowWidth(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { showAlert, isPending, startTransition } = useNotReadyAlert();
  const analytics = getAnalytics();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const handleResize = () => {
      if (viewWidth <= 1024) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };
    handleResize();
  }, [viewWidth]);

  return (
    <footer className="footer_wrapper">
      <div className="footer_inner">
        <div className="info_box">
          <span className="business">{t("footerTranslation.companyName")}</span>
          <div className="logo">
            <img
              src={logo}
              alt="로고"
              className="logo_img"
              loading="lazy"
              decoding="async"
            />
          </div>
          <div className="info">
            <p>{t("footerTranslation.representative")}</p>
            <p>{t("footerTranslation.headOffice1")}</p>
            <p>{t("footerTranslation.headOffice2")}</p>
            {mobile ? (
              <>
                <p>{t("footerTranslation.mobileInfo1")}</p>
                <p>{t("footerTranslation.mobileInfo2")}</p>
              </>
            ) : (
              <p>{t("footerTranslation.otherInformation")}</p>
            )}
          </div>
          <div className="ir">
            <Link
              to="/company/#irdown"
              className="ir_link"
              onClick={() => {
                logEvent(analytics, "main_content_footer_ir_btn_click", {
                  content_type: "Link",
                  content_id: "main_content_footer_ir_btn",
                });
              }}
            >
              {t("footerTranslation.inquiryBtn")}
            </Link>
            <span className="separator">|</span>
            <a
              href="https://www.saramin.co.kr/zf_user/search?searchType=search&company_cd=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C9%2C10&keydownAccess=&searchword=%EB%A6%AC%ED%84%B4%ED%94%8C%EB%9F%AC%EC%8A%A4&panel_type=&search_optional_item=y&search_done=y&panel_count=y&preview=y"
              target="_blank"
              rel="noopener noreferrer"
              className="ir_link"
              onClick={() => {
                logEvent(analytics, "main_content_footer_saramin_btn_click", {
                  content_type: "a",
                  content_id: "main_content_footer_saramin_btn",
                });
              }}
            >
              {t("footerTranslation.employmentBtn")}
            </a>
          </div>
          <div className="copyright">{t("footerTranslation.copyright")}</div>
          <div className="sns_box">
            <a
              // href="https://www.instagram.com"
              // target="_blank"
              // rel="noopener noreferrer"
              className="sns_link"
              onClick={() => {
                startTransition(showAlert);
                logEvent(analytics, "main_content_footer_insta_a_click", {
                  content_type: "a",
                  content_id: "main_content_footer_insta_a",
                });
              }}
              disabled={isPending}
            >
              <InstaSvg className="sns_icon insta_icon" />
            </a>
            <a
              // href="https://blog.naver.com"
              // target="_blank"
              // rel="noopener noreferrer"
              className="sns_link"
              onClick={() => {
                startTransition(showAlert);
                logEvent(analytics, "main_content_footer_blog_click", {
                  content_type: "a",
                  content_id: "main_content_footer_blog_a",
                });
              }}
              disabled={isPending}
            >
              <BlogSvg className="sns_icon blog_icon" />
            </a>
          </div>
        </div>
        <div className="map_box">
          <div className="title">{t("footerTranslation.siteMap")}</div>
          <div className="map">
            <ul className="sitemap_list">
              <li className="category">
                <Link
                  to="/"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_about_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_about_link",
                      }
                    );
                  }}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  onClick={() => {
                    logEvent(analytics, "main_content_footer_home_link_click", {
                      content_type: "Link",
                      content_id: "main_content_footer_home_link",
                    });
                  }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/#solution"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_solution_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_solution_link",
                      }
                    );
                  }}
                >
                  Solution
                </Link>
              </li>
              <li>
                <Link
                  to="/#platform"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_platform_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_platform_link",
                      }
                    );
                  }}
                >
                  Platform
                </Link>
              </li>
              <li>
                <Link
                  to="/#app"
                  onClick={() => {
                    logEvent(analytics, "main_content_footer_app_link_click", {
                      content_type: "Link",
                      content_id: "main_content_footer_app_link",
                    });
                  }}
                >
                  APP
                </Link>
              </li>
              <li>
                <Link
                  to="/#news"
                  onClick={() => {
                    logEvent(analytics, "main_content_footer_news_link_click", {
                      content_type: "Link",
                      content_id: "main_content_footer_news_link",
                    });
                  }}
                >
                  News
                </Link>
              </li>
              <li>
                <Link
                  to="/#contact"
                  onClick={() =>
                    logEvent(
                      analytics,
                      "main_content_footer_contact_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_contact_link",
                      }
                    )
                  }
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            <ul className="sitemap_list">
              <li className="category">
                <Link
                  to="/company"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_company_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_company_link",
                      }
                    );
                  }}
                >
                  Company
                </Link>
              </li>
              <li>
                <Link
                  to="/company/#vision"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_vision_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_vision_link",
                      }
                    );
                  }}
                >
                  Vision
                </Link>
              </li>
              <li>
                <Link
                  to="/company/#history"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_history_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_history_link",
                      }
                    );
                  }}
                >
                  History
                </Link>
              </li>
              <li>
                <Link
                  to="/company/#talent"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_talent_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_talent_link",
                      }
                    );
                  }}
                >
                  Type of talent
                </Link>
              </li>
              <li>
                <Link
                  to="/company/#irdown"
                  onClick={() => {
                    logEvent(analytics, "main_content_footer_ir_link_click", {
                      content_type: "Link",
                      content_id: "main_content_footer_ir_link",
                    });
                  }}
                >
                  IR Download
                </Link>
              </li>
            </ul>
            <ul className="sitemap_list">
              <li className="category">
                <Link
                  to="/service"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_service_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_service_link",
                      }
                    );
                  }}
                >
                  Service
                </Link>
              </li>
              <li>
                <Link
                  to="/service/solutionDetail"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_solution_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_solution_link",
                      }
                    );
                  }}
                >
                  Solution
                </Link>
              </li>
              <li>
                <Link
                  to="/service/serviceDetail"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_product_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_product_link",
                      }
                    );
                  }}
                >
                  Product
                </Link>
              </li>
              <li>
                <Link
                  to="/service/#retool"
                  onClick={() =>
                    logEvent(
                      analytics,
                      "main_content_footer_retool_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_retool_link",
                      }
                    )
                  }
                >
                  RE Tools
                </Link>
              </li>
            </ul>
            <ul className="sitemap_list">
              <li className="category">
                <Link
                  to="/news"
                  onClick={() =>
                    logEvent(analytics, "main_content_footer_news_link_click", {
                      content_type: "Link",
                      content_id: "main_content_footer_news_link",
                    })
                  }
                >
                  News
                </Link>
              </li>
            </ul>
            <ul className="sitemap_list">
              <li className="category">
                <Link
                  to="/contact"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_contact_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_contact_link",
                      }
                    );
                  }}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact/#way"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_way_to_come_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_way_to_come_link",
                      }
                    );
                  }}
                >
                  Way to Come
                </Link>
              </li>
              <li>
                <Link
                  to="/contact/#contactform"
                  onClick={() => {
                    logEvent(
                      analytics,
                      "main_content_footer_contact_us_link_click",
                      {
                        content_type: "Link",
                        content_id: "main_content_footer_contact_us_link",
                      }
                    );
                  }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="link_box">
            <div className="custom_dropdown_container" ref={dropdownRef}>
              <button
                className="dropdown_button"
                onClick={() => {
                  setIsDropdownOpen(!isDropdownOpen);
                  logEvent(
                    analytics,
                    "main_content_footer_family_site_dropdown_click",
                    {
                      content_type: "Dropdown",
                      content_id: "main_content_footer_family_site_dropdown",
                    }
                  );
                }}
              >
                {t("footerTranslation.familySite")}
                <span
                  className={`arrow ${isDropdownOpen ? "up" : "down"}`}
                ></span>
              </button>
              {isDropdownOpen && (
                <div className="dropdown_menu">
                  <a
                    // href="https://hanjogak.example.com"
                    // target="_blank"
                    // rel="noopener noreferrer"
                    onClick={() => {
                      startTransition(showAlert);
                      logEvent(
                        analytics,
                        "main_content_footer_family_site_dropdown_han_jogak_click",
                        {
                          content_type: "Link",
                          content_id:
                            "main_content_footer_family_site_dropdown_han_jogak",
                        }
                      );
                    }}
                    disabled={isPending}
                    className="dropdown_option"
                  >
                    Han Jogak
                  </a>
                  <a
                    href="http://daeyeonpnc.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dropdown_option"
                    onClick={() => {
                      logEvent(
                        analytics,
                        "main_content_footer_family_site_dropdown_deyeonpnc_click",
                        {
                          content_type: "Link",
                          content_id:
                            "main_content_footer_family_site_dropdown_deyeonpnc",
                        }
                      );
                    }}
                  >
                    DeyeonP&C
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
