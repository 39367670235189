import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./common/Layout/Layout";
import Main from "./pages/Main/Main";
import Company from "./pages/Company/Company";
import Service from "./pages/Service/Service";
import Contact from "./pages/Contact/Contact";
import News from "./pages/News/News";
import NotFound from "./common/NotFound/NotFound";
import NewsDetail from "./pages/NewsDetail/NewsDetail";
import SolutionDetail from "./pages/SolutionDetail/SolutionDetail";
import ServiceDetail from "./pages/ServiceDetail/ServiceDetail";
import MetaTagSet from "./components/Header/MetaTag";
import RouteChangeTracker from "./components/RouteChangeTracker/RouteChangeTracker";

function App() {
  return (
    <>
      <MetaTagSet
        title="리턴플러스 :: 블록체인 디지털 금융 자산 유동화 플랫폼 서비스 기업 "
        description="리턴플러스는 IT솔루션과 인프라를 구축하고 디지털 컨버전스를 연구하며 프롭테크와 블록체인 기반의 핀테크 기술을 통한 디지털 자산 및 실물 자산 유동화 플랫폼 서비스를 제공합니다"
        keywords="블록체인, 프롭테크, 조각투자, STO, RWA, IT솔루션 "
        url={process.env.PUBLIC_URL}
        imgsrc={process.env.PUBLIC_URL + "/returnplus.jpg"}
      />
      <BrowserRouter>
        <RouteChangeTracker />
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<Main />} />
            <Route path="/company" element={<Company />} />
            <Route path="/service" element={<Service />} />
            <Route
              path="/service/solutionDetail"
              element={<SolutionDetail />}
            />
            <Route path="/service/serviceDetail" element={<ServiceDetail />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:newsId" element={<NewsDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
