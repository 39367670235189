export const companyHistoryData = {
  2023: [
    {
      month: "12",
      content: "CompanyContents.History.2023.12",
      description: "Title",
    },
  ],
  2024: [
    {
      month: "02",
      content: "CompanyContents.History.2024.02",
      description: "Title",
    },
    {
      month: "03",
      content: "CompanyContents.History.2024.03",
      description: "subTitle",
    },
    {
      month: "04",
      content: "CompanyContents.History.2024.04",
      description: "subTitle",
    },
  ],
};
