import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getNewsList } from "../api/newsApi";
import { updateI18nNews } from "../i18n";

export const fetchNews = createAsyncThunk("news/fetchNews", async () => {
  const response = await getNewsList();
  return response;
});

const newsSlice = createSlice({
  name: "news",
  initialState: {
    allList: [],
    sendList: [],
    newsList: [],
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.news = action.payload.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        state.status = "succeeded";
        state.allList = action.payload;
        state.sendList = action.payload.slice(3);
        state.newsList = action.payload.slice(0, 3);
        updateI18nNews(action.payload);
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default newsSlice.reducer;
