import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as MoreArrowSvg } from "../../assets/images/서비스_런모어_화살표.svg";
import { ReactComponent as SolutionSvg } from "../../assets/images/서비스_솔루션.svg";
import { ReactComponent as ServiceSvg } from "../../assets/images/서비스_서비스.svg";
import useWindowWidth from "../../hooks/useWindowWidth";
import { getAnalytics, logEvent } from "firebase/analytics";

function ServiceContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);
  const viewWidth = useWindowWidth(0);
  const analytics = getAnalytics();

  useEffect(() => {
    if (viewWidth <= 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [viewWidth]);

  // 박스 정보 정의
  const boxes = [
    {
      title: "Solution",
      subTitle: t("serviceTranslation.content.solutionDetailTitle"),
      frameComponent: SolutionSvg,
      boxType: "solution",
      navigateTo: "./solutionDetail",
    },
    {
      title: "Service",
      subTitle: t("serviceTranslation.content.serviceDetailTitle"),
      frameComponent: ServiceSvg,
      boxType: "service",
      navigateTo: "./serviceDetail",
    },
  ];

  // hover 상태 관리
  const [hoverStates, setHoverStates] = useState(boxes.map(() => false));

  const handleMouseEnter = (index) => {
    setHoverStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? true : state))
    );
  };

  const handleMouseLeave = (index) => {
    setHoverStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? false : state))
    );
  };

  return (
    <div className="service_content_wrapper">
      <div className="service_content_inner">
        <div className="service_container">
          <p className="solution_title">Solution & Services</p>
          <p className="solution_text">
            {mobile
              ? t("serviceTranslation.content.mcontentExplan")
              : t("serviceTranslation.content.contentExplan")}
          </p>
          <div className="grid_container">
            {boxes.map((box, index) => (
              <div
                key={index}
                className={`box_container ${box.boxType}_box_container ${
                  hoverStates[index] ? "hovered" : ""
                }`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
                onClick={() => {
                  navigate(box.navigateTo);
                  logEvent(analytics, "service_content_solution_btn_click", {
                    content_type: "RouterButton",
                    content_id: box.navigateTo,
                  });
                }}
              >
                <div className="box_inner_context">
                  <div className="service_name">{box.title}</div>
                  <div className="sub_name">{box.subTitle}</div>
                  <p className="learn_more_link">
                    <span>Learn More</span>
                    <MoreArrowSvg
                      className="more_arrow"
                      style={{
                        fill: hoverStates[index] ? "#181918" : "#ffffff",
                      }}
                    />
                  </p>
                </div>
                <div className="frame_box">
                  <div
                    className="frame_content"
                    style={{ marginRight: index === 1 ? "20px" : "0px" }}
                  >
                    <box.frameComponent
                      className={`frame_component ${
                        box.boxType
                      }_frame_component ${hoverStates[index] ? "hovered" : ""}`}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceContent;
