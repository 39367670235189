const ImageModal = ({ imageSrc, onClose }) => {
  // 오버레이 클릭 시 모달 닫기
  const handleOverlayClick = () => {
    onClose();
  };



  return (
    <div className="patent_modal_overlay" onClick={handleOverlayClick}>
      <div className="patent_modal_content">
        <img src={imageSrc} alt="Patent" />
      </div>
    </div>
  );
};

export default ImageModal;