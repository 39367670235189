// ServiceDetail.jsx
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import hanjogak from "../../assets/images/서비스_서비스_한조각.avif";
import wallet from "../../assets/images/서비스_서비스_월렛.avif";
import trade from "../../assets/images/서비스_서비스_트레이딩.avif";
import { useEffect, useState } from "react";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useNotReadyAlert } from "../../hooks/useNotReadyAlert";
import { getAnalytics, logEvent } from "firebase/analytics";

function ServiceDetail() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showAlert, isPending, startTransition } = useNotReadyAlert();
  const [mobile, setMobile] = useState(false);
  const viewWidth = useWindowWidth(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const analytics = getAnalytics();

  useEffect(() => {
    if (viewWidth < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
      setCurrentSlide(0);
    }
  }, [viewWidth]);

  const serviceImageUrls = [hanjogak, wallet, trade];
  const mimageUrls = [
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/396f44d0-12cb-4a19-76cb-94f1625d8b00/mservice",
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/90397687-d13a-422f-451b-53abe9810800/mservice",
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/f9a98424-f2e2-4f1f-a348-45cbdbb48400/mservice",
  ];

  // 현재 사용 중인 이미지 배열 선택
  const currentImageUrls = mobile ? mimageUrls : serviceImageUrls;
  const totalSlides = currentImageUrls.length;

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? totalSlides - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev === totalSlides - 1 ? 0 : prev + 1));
  };

  // 터치 스와이프 핸들링
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart === null || touchEnd === null) return;
    const distance = touchStart - touchEnd;
    const minSwipeDistance = 50; // 스와이프 민감도 조절

    if (distance > minSwipeDistance) {
      // 왼쪽으로 스와이프
      handleNext();
    } else if (distance < -minSwipeDistance) {
      // 오른쪽으로 스와이프
      handlePrev();
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  return (
    <div className="service_detail_wrapper">
      <div className="service_container">
        <div className="service_detail_title">Service</div>
        <div className="service_detail_text" id="product">
          {t("serviceTranslation.serviceDetail.servicePageSubTitle")}
        </div>
        {mobile ? (
          // 모바일 뷰: 슬라이드(Carousel) 렌더링
          <div
            className="service_carousel"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div
              className="carousel_inner"
              style={{
                transform: `translateX(-${currentSlide * 100}%)`,
                transition: "transform 0.5s ease-in-out",
              }}
            >
              {currentImageUrls.map((url, index) => (
                <div className="service_list_container" key={index}>
                  <div className="service_content_box">
                    <div
                      className="service_image_box"
                      style={{ backgroundImage: `url(${url})` }}
                    >
                      {/* 데스크탑 뷰에서는 이미지 태그가 필요 없을 수 있습니다.
                          모바일 뷰에서는 필요하다면 유지하거나 조건부로 렌더링할 수 있습니다. */}
                      <img
                        src={url}
                        alt={`service_image_${index + 1}`}
                        className="service_image"
                      />
                    </div>
                    <div className="service_item">
                      <div className="item_title_box">
                        <div className="service_item_title">
                          {t(
                            `serviceTranslation.serviceDetail.service${
                              index + 1
                            }.serviceTitle`
                          )}
                        </div>
                        <div className="sub_title">
                          {t(
                            `serviceTranslation.serviceDetail.service${
                              index + 1
                            }.serviceSubTitle`
                          )}
                        </div>
                      </div>
                      <div className="text_box">
                        {t(
                          `serviceTranslation.serviceDetail.service${
                            index + 1
                          }.serviceContent`
                        )}
                      </div>
                      {/* 버튼 조건 수정: 두 조건을 하나의 논리식으로 결합 */}
                      {["한조각", "Hanjokak"].includes(
                        t(
                          `serviceTranslation.serviceDetail.service${
                            index + 1
                          }.serviceTitle`
                        )
                      ) &&
                        !mobile && (
                          <button
                            className="navigate_button"
                            onClick={() => {
                              startTransition(showAlert);
                              logEvent(
                                analytics,
                                "service_content_service_btn_click",
                                {
                                  content_type: "RouterButton",
                                  content_id: "service_content_serviceDetail",
                                }
                              );
                            }}
                            disabled={isPending}
                          >
                            Real Estate Tools
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* 네비게이션 버튼 */}
            <button
              className="carousel_button prev"
              onClick={handlePrev}
              aria-label="Previous Slide"
            >
              &#10094;
            </button>
            <button
              className="carousel_button next"
              onClick={handleNext}
              aria-label="Next Slide"
            >
              &#10095;
            </button>
          </div>
        ) : (
          currentImageUrls.map((url, index) => (
            <div className="service_list_container" key={index}>
              <div className="service_content_box">
                <div
                  className="service_image_box"
                  style={{ backgroundImage: `url(${url})` }}
                ></div>
                <div className="service_item">
                  <div className="item_title_box">
                    <div className="service_item_title">
                      {t(
                        `serviceTranslation.serviceDetail.service${
                          index + 1
                        }.serviceTitle`
                      )}
                    </div>
                    <div className="sub_title">
                      {t(
                        `serviceTranslation.serviceDetail.service${
                          index + 1
                        }.serviceSubTitle`
                      )}
                    </div>
                  </div>
                  <div className="text_box">
                    {t(
                      `serviceTranslation.serviceDetail.service${
                        index + 1
                      }.serviceContent`
                    )}
                  </div>
                  {/* 조건 수정: !mobile && <button> */}
                  {["한조각", "Hanjokak"].includes(
                    t(
                      `serviceTranslation.serviceDetail.service${
                        index + 1
                      }.serviceTitle`
                    )
                  ) &&
                    !mobile && (
                      <button
                        className="navigate_button"
                        onClick={() => startTransition(showAlert)}
                        disabled={isPending}
                      >
                        Real Estate Tools
                      </button>
                    )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default ServiceDetail;
