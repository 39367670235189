import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { ReactComponent as StoSvg } from "../../assets/images/메인_솔루션_STO.svg";
import { ReactComponent as TradeSvg } from "../../assets/images/메인_솔루션_트레이딩.svg";
import { ReactComponent as WalletSvg } from "../../assets/images/메인_솔루션_월렛.svg";
import { ReactComponent as GoogleSvg } from "../../assets/images/메인_앱_구글.svg";
import { ReactComponent as AppleSvg } from "../../assets/images/메인_앱_애플.svg";
import { ReactComponent as ElecBulbSvg } from "../../assets/images/메인_뉴스_전구 아이콘.svg";
import { ReactComponent as GoToNewsSvg } from "../../assets/images/메인_뉴스_바로가기_화살표.svg";
import { ReactComponent as GoToSvg } from "../../assets/images/메인_뉴스_화살표.svg";
import { ReactComponent as PlatformDropSvg } from "../../assets/images/platform_dropdown.svg";
import news1 from "../../assets/images/메인_뉴스1.svg";
import news2 from "../../assets/images/메인_뉴스2.svg";
import news3 from "../../assets/images/메인_뉴스3.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHashScroll from "../../hooks/useHashScroll.js";
import useIntersection from "../../hooks/useIntersection.js";
import { useNotReadyAlert } from "../../hooks/useNotReadyAlert.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../../store/newsSlice";
import { solutionData } from "../../util/data/Main/staticData.js";
import SkeletonNewsItem from "../SkeletonUI/SkeletonNewsItem.js";
import useWindowWidth from "../../hooks/useWindowWidth.js";
import useInterval from "../../hooks/useInterval";
import { getAnalytics, logEvent } from "firebase/analytics";

function MainContent() {
  const { t } = useTranslation();
  const location = useHashScroll();
  const dispatch = useDispatch();
  const { newsList, status } = useSelector((state) => state.news);

  const [activeCategory, setActiveCategory] = useState("Hanjogak");
  const [i18nActiveCategory, setI18nActiveCategory] = useState("hanjogak");
  const [overlayTranslate, setOverlayTranslate] = useState(0);
  const [contactVisible, setContactVisible] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [solutionCurrentSlide, setSolutionCurrentSlide] = useState(0);
  const [solutionStartX, setSolutionStartX] = useState(null);
  const [solutionCurrentX, setSolutionCurrentX] = useState(null);
  const [solutionIsDragging, setSolutionIsDragging] = useState(false);
  const [activeMobileCategory, setActiveMobileCategory] = useState("Hanjogak");
  const viewWidth = useWindowWidth(0);
  const appBoxRef = useRef(null);
  const contactBoxRef = useRef(null);
  const overlayRef = useRef(null);
  const mainItemBoxRef = useRef(null);
  const mobilePlatformTextRefs = useRef({});
  const maxTranslate = 900;
  const { showAlert, isPending, startTransition } = useNotReadyAlert();
  const [newsCurrentSlide, setNewsCurrentSlide] = useState(0);
  const [progress, setProgress] = useState(0);
  const slideInterval = 5000;
  const updateInterval = 50;
  const incrementValue = 100 / (slideInterval / updateInterval);
  const [newsStartX, setNewsStartX] = useState(null);
  const [newsCurrentX, setNewsCurrentX] = useState(null);
  const [newsIsDragging, setNewsIsDragging] = useState(false);
  const newsSliderContainerRef = useRef(null);
  const [slideWidth, setSlideWidth] = useState(0);
  const analytics = getAnalytics();

  useEffect(() => {
    dispatch(fetchNews());
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      if (overlayRef.current) {
        const rect = overlayRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        const visibleHeight =
          Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
        const totalHeight = rect.height;

        let prog = visibleHeight / totalHeight;
        prog = Math.min(Math.max(prog, 0), 1);

        let newTranslate = prog * maxTranslate;

        if (rect.top < 0 && rect.bottom < windowHeight) {
          newTranslate = maxTranslate;
        }

        setOverlayTranslate(newTranslate);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  const isAppVisible = useIntersection(appBoxRef, 0.1);
  const isContactVisible = useIntersection(contactBoxRef, 0.1);

  useEffect(() => {
    if (isContactVisible) {
      setContactVisible(true);
    }
  }, [isContactVisible]);

  useEffect(() => {
    if (viewWidth <= 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [viewWidth]);

  const data = useMemo(() => {
    return t("mainTranslation.platform", { returnObjects: true });
  }, [t]);

  const newsImgs = [news1, news2, news3];
  const newsMobileImgs = [
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/7417f006-8cfc-4b32-5c3b-c9990f68ca00/mainnews",
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/9ab9b07f-09b0-485c-9e63-ecb41724b600/mainnews",
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/be427225-0dd6-420d-0cb9-b2ed31c74500/mainnews",
  ];

  const totalSlides = 3;

  const handlePrevSlide = useCallback(() => {
    logEvent(analytics, "main_content_mobile_solution_prev_btn", {
      content_type: "SlideButton",
    });
    if (solutionCurrentSlide > 0) {
      setSolutionCurrentSlide((prev) => prev - 1);
    } else {
      setSolutionCurrentSlide(totalSlides - 1);
    }
  }, [solutionCurrentSlide, totalSlides]);

  const handleNextSlide = useCallback(() => {
    logEvent(analytics, "main_content_mobile_solution_next_btn", {
      content_type: "SlideButton",
    });
    if (solutionCurrentSlide < totalSlides - 1) {
      setSolutionCurrentSlide((prev) => prev + 1);
    } else {
      setSolutionCurrentSlide(0);
    }
  }, [solutionCurrentSlide, totalSlides]);

  const platformItems = [
    { category: "Hanjogak", i18n: "hanjogak" },
    { category: "Wallet", i18n: "wallet" },
    { category: "Trading", i18n: "trading" },
  ];

  useEffect(() => {
    platformItems.forEach(({ category }) => {
      const contentEl = mobilePlatformTextRefs.current[category];
      if (!contentEl) return;

      if (activeMobileCategory === category) {
        const scrollHeight = contentEl.scrollHeight;
        contentEl.style.height = scrollHeight + "px";
        contentEl.style.opacity = 1;
      } else {
        contentEl.style.height = "0px";
        contentEl.style.opacity = 0;
      }
    });
  }, [activeMobileCategory, platformItems]);

  const handleNewsNextSlide = useCallback(() => {
    if ((newsList?.length || 0) < 1) return;
    if (newsCurrentSlide < (newsList?.length || 0) - 1) {
      setNewsCurrentSlide((prev) => prev + 1);
      setProgress(0);
    } else {
      setNewsCurrentSlide(0);
      setProgress(0);
    }
  }, [newsList, newsCurrentSlide]);

  const handleNewsPrevSlide = useCallback(() => {
    if ((newsList?.length || 0) < 1) return;
    if (newsCurrentSlide > 0) {
      setNewsCurrentSlide((prev) => prev - 1);
      setProgress(0);
    } else {
      setNewsCurrentSlide((newsList.length || 1) - 1);
      setProgress(0);
    }
  }, [newsList, newsCurrentSlide]);

  useInterval(
    () => {
      if (!mobile) return;
      setProgress((prev) => {
        const newVal = prev + incrementValue;
        if (newVal >= 100) {
          handleNewsNextSlide();
          return 0;
        }
        return newVal;
      });
    },
    mobile && (newsList?.length || 0) > 0 ? updateInterval : null,
    [newsCurrentSlide, newsList, mobile]
  );

  const [slideDistance, setSlideDistance] = useState(0);
  useEffect(() => {
    if (
      mainItemBoxRef.current &&
      mainItemBoxRef.current.querySelector(".item")
    ) {
      const item = mainItemBoxRef.current.querySelector(".item");
      const itemWidth = item.getBoundingClientRect().width;
      setSlideDistance(itemWidth);
    }
  }, [viewWidth, mobile, solutionCurrentSlide, newsList]);

  useEffect(() => {
    if (newsSliderContainerRef.current && newsList && newsList.length > 0) {
      const firstSlide =
        newsSliderContainerRef.current.querySelector(".main_news_slide");
      if (firstSlide) {
        const width = firstSlide.getBoundingClientRect().width + 26;
        setSlideWidth(width);
      }
    }
  }, [newsList]);

  const handleSolutionDragStart = useCallback((clientX) => {
    setSolutionStartX(clientX);
    setSolutionCurrentX(clientX);
    setSolutionIsDragging(true);
  }, []);

  const handleSolutionDragMove = useCallback(
    (clientX) => {
      if (!solutionIsDragging) return;
      setSolutionCurrentX(clientX);
    },
    [solutionIsDragging]
  );

  const handleSolutionDragEnd = useCallback(() => {
    if (
      !solutionIsDragging ||
      solutionStartX === null ||
      solutionCurrentX === null
    )
      return;

    const diff = solutionStartX - solutionCurrentX;
    const threshold = 50;

    if (diff > threshold) {
      handleNextSlide();
    } else if (diff < -threshold) {
      handlePrevSlide();
    }

    setSolutionStartX(null);
    setSolutionCurrentX(null);
    setSolutionIsDragging(false);
  }, [
    solutionIsDragging,
    solutionStartX,
    solutionCurrentX,
    handleNextSlide,
    handlePrevSlide,
  ]);

  const onSolutionTouchStart = (e) =>
    handleSolutionDragStart(e.touches[0].clientX);
  const onSolutionTouchMove = (e) =>
    handleSolutionDragMove(e.touches[0].clientX);
  const onSolutionTouchEnd = () => handleSolutionDragEnd();

  const onSolutionMouseDown = (e) => handleSolutionDragStart(e.clientX);
  const onSolutionMouseMove = (e) => {
    if (solutionIsDragging) {
      e.preventDefault();
      handleSolutionDragMove(e.clientX);
    }
  };
  const onSolutionMouseUp = () => handleSolutionDragEnd();
  const onSolutionMouseLeave = () => handleSolutionDragEnd();

  const handleNewsDragStart = useCallback((clientX) => {
    setNewsStartX(clientX);
    setNewsCurrentX(clientX);
    setNewsIsDragging(true);
  }, []);

  const handleNewsDragMove = useCallback(
    (clientX) => {
      if (!newsIsDragging) return;
      setNewsCurrentX(clientX);
    },
    [newsIsDragging]
  );

  const handleNewsDragEnd = useCallback(() => {
    if (!newsIsDragging || newsStartX === null || newsCurrentX === null) return;

    const diff = newsStartX - newsCurrentX;
    const threshold = 50;

    if (diff > threshold) {
      handleNewsNextSlide();
    } else if (diff < -threshold) {
      handleNewsPrevSlide();
    }

    setNewsStartX(null);
    setNewsCurrentX(null);
    setNewsIsDragging(false);
  }, [
    newsIsDragging,
    newsStartX,
    newsCurrentX,
    handleNewsNextSlide,
    handleNewsPrevSlide,
  ]);

  const onNewsTouchStart = (e) => handleNewsDragStart(e.touches[0].clientX);
  const onNewsTouchMove = (e) => handleNewsDragMove(e.touches[0].clientX);
  const onNewsTouchEnd = () => handleNewsDragEnd();

  const onNewsMouseDown = (e) => handleNewsDragStart(e.clientX);
  const onNewsMouseMove = (e) => {
    if (newsIsDragging) {
      e.preventDefault();
      handleNewsDragMove(e.clientX);
    }
  };
  const onNewsMouseUp = () => handleNewsDragEnd();
  const onNewsMouseLeave = () => handleNewsDragEnd();

  return (
    <div className="main_content">
      <section className="content_box">
        <div className="content_inner">
          <h2 id="solution" className="title">
            Solution
          </h2>
          <p className="sub_title">Offering</p>
          {mobile && (
            <div className="main_slider_controls">
              <div className="main_slide_btn_box">
                <button
                  className="main_slide_btn"
                  onClick={handlePrevSlide}
                  disabled={solutionCurrentSlide === 0}
                  aria-label="이전 슬라이드로 이동"
                >
                  <svg
                    width="13"
                    height="22"
                    viewBox="0 0 13 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.73 2.12L4.11 10.74L12.73 19.36L10.68 21.42L0 10.74L10.68 0.0600586L12.73 2.12Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <button
                  className="main_slide_btn"
                  onClick={handleNextSlide}
                  disabled={solutionCurrentSlide === totalSlides - 1}
                  aria-label="다음 슬라이드로 이동"
                >
                  <svg
                    width="14"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.5 2.12L9.12 10.74L0.5 19.36L2.55 21.42L13.23 10.74L2.55 0.0600586L0.5 2.12Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
              <div className="main_slide_pagination">
                <span>{solutionCurrentSlide + 1}</span>/
                <span>{totalSlides}</span>
              </div>
            </div>
          )}
          <div
            className={`main_item_box ${mobile ? "main_slider_mode" : ""}`}
            ref={mainItemBoxRef}
            style={
              mobile
                ? {
                    width: `${slideDistance * totalSlides + 200}px`,
                    transform: `translateX(-${
                      solutionCurrentSlide * slideDistance
                    }px)`,
                    transition: "transform 0.3s ease",
                  }
                : {}
            }
            onTouchStart={onSolutionTouchStart}
            onTouchMove={onSolutionTouchMove}
            onTouchEnd={onSolutionTouchEnd}
            onMouseDown={onSolutionMouseDown}
            onMouseMove={onSolutionMouseMove}
            onMouseUp={onSolutionMouseUp}
            onMouseLeave={onSolutionMouseLeave}
          >
            <div className="item">
              <span className="item_title">Wallet</span>
              <div className="item_sub_title">
                {t("mainTranslation.solution.wallet")}
              </div>
              <WalletSvg className="wallet_svg" />
              <Link className="item_link" to="/service/serviceDetail">
                <span className="item_link_inner">
                  <span>Start</span>
                  <GoToSvg className="go_svg" />
                </span>
              </Link>
            </div>
            <div className="item">
              <span className="item_title">STO Platform</span>
              <div className="item_sub_title">
                {t("mainTranslation.solution.platform")}
              </div>
              <StoSvg className="sto_svg" />
              <Link className="item_link" to="/service/serviceDetail">
                <span className="item_link_inner">
                  <span>View</span>
                  <GoToSvg className="go_svg" />
                </span>
              </Link>
            </div>
            <div className="item">
              <span className="item_title">Trading</span>
              <div className="item_sub_title">
                {t("mainTranslation.solution.trading")}
              </div>
              <TradeSvg className="trade_svg" />
              <Link className="item_link" to="/service/serviceDetail">
                <span className="item_link_inner">
                  <span>Obtain</span>
                  <GoToSvg className="go_svg" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {mobile ? (
        <section className="content_box">
          <div className="content_inner">
            <h2 id="platform" className="title mobile_title">
              Platform
            </h2>
            <div className="mobile_platform_section">
              {platformItems.map((item) => {
                const isActive = activeMobileCategory === item.category;
                return (
                  <div key={item.category} className="mobile_platform_box">
                    <button
                      className="mobile_platform_button"
                      onClick={() => {
                        setActiveMobileCategory(
                          isActive ? null : item.category
                        );
                        logEvent(
                          analytics,
                          "main_content_mobile_platform_toggle_btn_click",
                          {
                            content_type: "ToggleButton",
                            content_id: `platform-toggle-btn-${item.category}`,
                          }
                        );
                      }}
                      aria-expanded={isActive}
                      aria-controls={`platform-text-${item.category}`}
                    >
                      <div
                        className={`mobile_platform_title_box ${
                          isActive ? "mobile_active" : ""
                        }`}
                      >
                        <span className="mobile_platform_title">
                          {item.category}
                        </span>
                        {!isActive && (
                          <PlatformDropSvg className="platform_drop_svg" />
                        )}
                      </div>
                      <img
                        src={
                          mobile
                            ? solutionData[item.i18n]?.imagem
                            : solutionData[item.i18n]?.image
                        }
                        alt={item.i18n}
                      />
                    </button>
                    <div
                      className={`mobile_platform_text ${
                        isActive ? "active" : ""
                      }`}
                      id={`platform-text-${item.category}`}
                      ref={(el) => {
                        mobilePlatformTextRefs.current[item.category] = el;
                      }}
                    >
                      <div className="mobile_platform_text_inner">
                        <div className="inner_content">
                          <h3 className="content_title">
                            {data[item.i18n]?.title1}
                          </h3>
                          <p className="content_description_paragraph">
                            {data[item.i18n]?.description1}
                          </p>
                          <h3 className="content_title">
                            {data[item.i18n]?.title2}
                          </h3>
                          <ul className="content_description_list">
                            <li>{data[item.i18n]?.list1}</li>
                            <li>{data[item.i18n]?.list2}</li>
                            <li>{data[item.i18n]?.list3}</li>
                            <li>{data[item.i18n]?.list4}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      ) : (
        <section className="content_box">
          <div className="content_inner">
            <h2 id="platform" className="title">
              Platform
            </h2>
            <div className="button_group">
              <button
                className={`button ${
                  activeCategory === "Hanjogak" ? "active" : ""
                }`}
                onClick={() => {
                  setActiveCategory("Hanjogak");
                  setI18nActiveCategory("hanjogak");
                  logEvent(
                    analytics,
                    "main_content_platform_toggle_btn_click",
                    {
                      content_type: "ToggleButton",
                      content_id: "platform-toggle-btn-hanjogak",
                    }
                  );
                }}
              >
                Han jogak
              </button>
              <button
                className={`button ${
                  activeCategory === "Wallet" ? "active" : ""
                }`}
                onClick={() => {
                  setActiveCategory("Wallet");
                  setI18nActiveCategory("wallet");
                  logEvent(
                    analytics,
                    "main_content_platform_toggle_btn_click",
                    {
                      content_type: "ToggleButton",
                      content_id: "platform-toggle-btn-wallet",
                    }
                  );
                }}
              >
                Wallet
              </button>
              <button
                className={`button ${
                  activeCategory === "Trading" ? "active" : ""
                }`}
                onClick={() => {
                  setActiveCategory("Trading");
                  setI18nActiveCategory("trading");
                  logEvent(
                    analytics,
                    "main_content_platform_toggle_btn_click",
                    {
                      content_type: "ToggleButton",
                      content_id: "platform-toggle-btn-trading",
                    }
                  );
                }}
              >
                Trading
              </button>
            </div>
            {data && (
              <div className="content_container">
                <img
                  className="content_image"
                  src={solutionData[i18nActiveCategory]?.image}
                  alt={i18nActiveCategory}
                  loading="lazy"
                  decoding="async"
                />
                <div className="content_text_box">
                  <div className="content_text">
                    <h3 className="content_title">
                      {data[i18nActiveCategory]?.title1}
                    </h3>
                    <p className="content_description_paragraph">
                      {data[i18nActiveCategory]?.description1}
                    </p>
                  </div>
                  <div className="content_text">
                    <h3 className="content_title">
                      {data[i18nActiveCategory]?.title2}
                    </h3>
                    <ul className="content_description_list">
                      <li>{data[i18nActiveCategory]?.list1}</li>
                      <li>{data[i18nActiveCategory]?.list2}</li>
                      <li>{data[i18nActiveCategory]?.list3}</li>
                      <li>{data[i18nActiveCategory]?.list4}</li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      )}

      <section className="app_box" id="app" ref={appBoxRef}>
        <img
          className="app_img"
          src={
            mobile
              ? "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/ec27ffff-eb44-465e-0604-76ec8da5e800/app"
              : "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/6e92e80b-1b13-48db-cc44-578e725f1000/app"
          }
          alt="App Image"
          loading="lazy"
          decoding="async"
        />
        <div className="app_content_box">
          <div
            className={`app_title ${
              isAppVisible || contactVisible ? "visible" : ""
            }`}
          >
            {t("mainTranslation.app.title")}
          </div>
          <div
            className={`app_sub_title ${
              isAppVisible || contactVisible ? "visible" : ""
            }`}
          >
            {t("mainTranslation.app.content")}
          </div>
          <div className="app_btn_box">
            <button
              className={`app_btn ${
                isAppVisible || contactVisible ? "visible" : ""
              }`}
              onClick={() => {
                startTransition(showAlert);
                logEvent(analytics, "main_content_apple_store_btn_click", {
                  content_type: "Button",
                  content_id: "apple-store-btn",
                });
              }}
              disabled={isPending}
            >
              <AppleSvg className="apple_svg" />
              <div className="app_btn_text">App Store</div>
            </button>
            <button
              className={`app_btn ${
                isAppVisible || contactVisible ? "visible" : ""
              }`}
              onClick={() => {
                startTransition(showAlert);
                logEvent(analytics, "main_content_google_play_btn_click", {
                  content_type: "Button",
                  content_id: "google-play-btn",
                });
              }}
              disabled={isPending}
            >
              <GoogleSvg className="google_svg" />
              <div className="app_btn_text">Google Play</div>
            </button>
          </div>
        </div>
      </section>

      {mobile ? (
        <section className="content_box">
          <div className="content_inner">
            <h2 id="news" className="title mobile_title">
              News
            </h2>
            <div
              className="main_news_slider_wrapper"
              onTouchStart={onNewsTouchStart}
              onTouchMove={onNewsTouchMove}
              onTouchEnd={onNewsTouchEnd}
              onMouseDown={onNewsMouseDown}
              onMouseMove={onNewsMouseMove}
              onMouseUp={onNewsMouseUp}
              onMouseLeave={onNewsMouseLeave}
            >
              <div
                className="main_news_slider_container"
                ref={newsSliderContainerRef}
              >
                {status === "loading"
                  ? Array.from({ length: 3 }).map((_, idx) => (
                      <div className="main_news_slide loading" key={idx}></div>
                    ))
                  : newsList?.map((news, index) => (
                      <div
                        className={`main_news_slide ${
                          newsCurrentSlide === index ? "active" : ""
                        }`}
                        key={news._id}
                        style={{
                          opacity: newsCurrentSlide === index ? 1 : 0,
                          zIndex: newsCurrentSlide === index ? 2 : 1,
                        }}
                      >
                        <Link
                          to={`/news/${news._id}`}
                          className="main_news_item"
                        >
                          <div className="main_news_content">
                            <div className="main_news_date">
                              <div>
                                <ElecBulbSvg className="elec_svg" />
                                <span>
                                  {news.date.split(" ")[0].replace(/-/g, ".")}
                                </span>
                              </div>
                              <GoToSvg className="go_svg_2" />
                            </div>
                            <span className="main_news_title">
                              {t(`news_${news._id}_title`)}
                            </span>
                            <div className="main_news_tag_list">
                              <span className="main_news_tag">
                                {/* # {t(`news_${news._id}_tag`)} */}# News
                              </span>
                            </div>
                          </div>
                          <div className="main_news_img_box">
                            <img
                              className={`main_news_prev_img ${
                                index === 2 ? "edit" : ""
                              }`}
                              src={
                                mobile ? newsMobileImgs[index] : newsImgs[index]
                              }
                              alt="News Image"
                              loading="lazy"
                              decoding="async"
                            />
                          </div>
                        </Link>
                        <button
                          className="main_news_slide_btn main_news_prev_slide_btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNewsPrevSlide();
                          }}
                          aria-label="이전 슬라이드로 이동"
                        >
                          <svg
                            width="13"
                            height="22"
                            viewBox="0 0 13 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.73 2.12L4.11 10.74L12.73 19.36L10.68 21.42L0 10.74L10.68 0.0600586L12.73 2.12Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                        <button
                          className="main_news_slide_btn main_news_next_slide_btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNewsNextSlide();
                          }}
                          aria-label="다음 슬라이드로 이동"
                        >
                          <svg
                            width="14"
                            height="22"
                            viewBox="0 0 14 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.5 2.12L9.12 10.74L0.5 19.36L2.55 21.42L13.23 10.74L2.55 0.0600586L0.5 2.12Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
              </div>
            </div>
            <div className="main_news_progress_bar_wrapper">
              <div className="progress_bar_background"></div>
              <div
                className="progress_bar_indicator"
                style={{
                  left: `${newsCurrentSlide * (100 / totalSlides)}%`,
                }}
              ></div>
            </div>
            <Link to="/news" className="goto_news_btn">
              <span>{t("newsTranslation.goNewsPage")}</span>
              <GoToNewsSvg className="go_news_svg" />
            </Link>
          </div>
        </section>
      ) : (
        <section className="content_box">
          <div className="content_inner">
            <h2 id="news" className="title mobile_title">
              News
            </h2>
            <div className="news_box">
              {status === "loading"
                ? Array.from({ length: 3 }).map((_, idx) => (
                    <SkeletonNewsItem key={idx} />
                  ))
                : newsList?.map((news, index) => (
                    <Link
                      key={news._id}
                      to={`/news/${news._id}`}
                      className="news_item"
                      onClick={() => {
                        logEvent(analytics, "main_content_news_item_click", {
                          content_type: "Link",
                          content_id: news._id,
                          content_name: news.title,
                        });
                      }}
                    >
                      <div className="news_content">
                        <div className="news_date">
                          <div>
                            <ElecBulbSvg className="elec_svg" />
                            <span>
                              {news.date.split(" ")[0].replace(/-/g, ".")}
                            </span>
                          </div>
                          <GoToSvg className="go_svg_2" />
                        </div>
                        <span className="news_title">
                          {t(`news_${news._id}_title`)}
                        </span>
                        <div className="news_tag_list">
                          <span className="news_tag"># {news.tags}</span>
                        </div>
                      </div>
                      <div className="news_img_box">
                        <img
                          className={`news_prev_img ${
                            index === 2 ? "edit" : ""
                          }`}
                          src={newsImgs[index]}
                          alt="News Image"
                          loading="lazy"
                          decoding="async"
                        />
                      </div>
                    </Link>
                  ))}
            </div>
            <Link
              to="/news"
              className="goto_news_btn"
              onClick={() => {
                logEvent(analytics, "main_content_goto_news_btn_click", {
                  content_type: "Link",
                  content_id: "main_content_goto_news_btn",
                });
              }}
            >
              <span>{t("newsTranslation.goNewsPage")}</span>
              <GoToNewsSvg className="go_news_svg" />
            </Link>
          </div>
        </section>
      )}

      <section className="overlay_container" ref={overlayRef}>
        <img
          className="overlay_img"
          src={
            mobile
              ? "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/6f87ddc6-3286-4596-02ac-a3480f699900/copy"
              : "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/f1afe2eb-f621-4663-24d4-061b704f3000/copy"
          }
          loading="lazy"
          decoding="async"
          alt=""
        />
        {mobile ? (
          <span className="overlay_text">
            {t("mainTranslation.copyRight.contentm")}
          </span>
        ) : (
          <span className="overlay_text">
            {t("mainTranslation.copyRight.content")}
          </span>
        )}
        <div
          className="left_overlay"
          style={{
            transform: `translate3d(${-overlayTranslate / 16}rem, 0, 0)`,
          }}
        ></div>
        <div
          className="right_overlay"
          style={{
            transform: `translate3d(${overlayTranslate / 16}rem, 0, 0)`,
          }}
        ></div>
      </section>

      <div className="last_content" ref={contactBoxRef} id="contact">
        <span className={`last_title ${contactVisible ? "visible" : ""}`}>
          {t("mainTranslation.contact.content1")}
        </span>
        <div className="last_sub_title">
          <span
            className={`last_sub_title_span ${
              contactVisible ? "visible delay_06" : ""
            }`}
          >
            {t("mainTranslation.contact.content2")}
          </span>
          <br />
          <span
            className={`last_sub_title_span ${
              contactVisible ? "visible delay_07" : ""
            }`}
          >
            {t("mainTranslation.contact.content3")}
          </span>
        </div>
        <Link
          to="/contact/#contactform"
          className={`inquiry_btn ${contactVisible ? "visible" : ""}`}
          onClick={() => {
            logEvent(analytics, "main_content_contact_us_btn_click", {
              content_type: "Link",
              content_id: "main_content_contact_us",
            });
          }}
        >
          {t("mainTranslation.contactUsBtn")}
        </Link>
      </div>
    </div>
  );
}

export default MainContent;
