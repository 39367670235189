import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../../store/newsSlice";
import SkeletonNewsContent from "../SkeletonUI/SkeletonNewsContent";
import { getAnalytics, logEvent } from "firebase/analytics";

function NewsContent() {
  const { t } = useTranslation();
  const { sendList, status } = useSelector((state) => state.news);
  const dispatch = useDispatch();
  const [selectedTag, setSelectedTag] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const filteredList = sendList.filter((item) => {
    if (selectedTag === "all") return true;
    return item.tags.includes(selectedTag);
  });

  const analytics = getAnalytics();
  const totalPages = Math.ceil(filteredList.length / itemsPerPage);
  const paginatedList = filteredList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleTabClick = (tag) => {
    logEvent(analytics, "news_content_tab_click", {
      content_type: "Tab",
      content_id: tag,
    });
    setSelectedTag(tag);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages || 1);
    }
  }, [filteredList, totalPages, currentPage]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchNews());
    }
  }, [status, dispatch]);

  const generatePageButtons = () => {
    const pageButtons = [];
    const maxPageButtons = 7;
    const sideButtons = 2;
    if (totalPages <= maxPageButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
          <button
            key={i}
            className={`page_button ${currentPage === i ? "active" : ""}`}
            onClick={() => handlePageChange(i)}
            disabled={currentPage === i}
          >
            {i}
          </button>
        );
      }
    } else {
      const firstPage = 1;
      const lastPage = totalPages;
      let startPage = currentPage - sideButtons;
      let endPage = currentPage + sideButtons;

      if (startPage < 2) {
        endPage += 2 - startPage;
        startPage = 2;
      }
      if (endPage > totalPages - 1) {
        startPage -= endPage - (totalPages - 1);
        endPage = totalPages - 1;
        if (startPage < 2) startPage = 2;
      }

      pageButtons.push(
        <button
          key={firstPage}
          className={`page_button ${currentPage === firstPage ? "active" : ""}`}
          onClick={() => handlePageChange(firstPage)}
          disabled={currentPage === firstPage}
        >
          {firstPage}
        </button>
      );

      if (startPage > 2) {
        pageButtons.push(
          <span key="start-ellipsis" className="ellipsis">
            ...
          </span>
        );
      }

      for (let i = startPage; i <= endPage; i++) {
        pageButtons.push(
          <button
            key={i}
            className={`page_button ${currentPage === i ? "active" : ""}`}
            onClick={() => handlePageChange(i)}
            disabled={currentPage === i}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages - 1) {
        pageButtons.push(
          <span key="end-ellipsis" className="ellipsis">
            ...
          </span>
        );
      }

      pageButtons.push(
        <button
          key={lastPage}
          className={`page_button ${currentPage === lastPage ? "active" : ""}`}
          onClick={() => handlePageChange(lastPage)}
          disabled={currentPage === lastPage}
        >
          {lastPage}
        </button>
      );
    }

    return pageButtons;
  };

  return (
    <div className="news_content_wrapper">
      <div className="news_inner">
        <div className="news_section">
          <div className="tab_box">
            <div className="tabs">
              <button
                className={`tab ${selectedTag === "all" ? "active" : ""}`}
                onClick={() => handleTabClick("all")}
              >
                {t("newsTranslation.allBtn")}
              </button>
              <button
                className={`tab ${selectedTag === "뉴스" ? "active" : ""}`}
                onClick={() => handleTabClick("뉴스")}
              >
                {t("newsTranslation.newBtn")}
              </button>
              <button
                className={`tab ${selectedTag === "소식" ? "active" : ""}`}
                onClick={() => handleTabClick("소식")}
              >
                {t("newsTranslation.tidingsBtn")}
              </button>
            </div>
          </div>

          {status === "loading" ? (
            <ul className="news_list">
              {Array.from({ length: itemsPerPage }).map((_, index) => (
                <SkeletonNewsContent key={index} />
              ))}
            </ul>
          ) : filteredList.length === 0 ? (
            <div className="no_news_message">
              <p>{t("newsTranslation.noData")}</p>
            </div>
          ) : (
            <ul className="news_list">
              {paginatedList.map((data) => (
                <li className="news_item" key={data._id}>
                  <Link
                    to={`/news/${data._id}`}
                    className="news_link"
                    onClick={() =>
                      logEvent(analytics, "news_content_item_click", {
                        content_type: "Item",
                        content_id: data._id,
                        content_name: data.title,
                        content_category: data.tag,
                      })
                    }
                  >
                    <img
                      src={data.image}
                      alt={data.title}
                      loading="lazy"
                      decoding="async"
                      className="news_img"
                    />
                    <div className="content_box">
                      <span className="news_title">
                        {t(`news_${data._id}_title`)}
                      </span>
                      <span className="news_at">
                        {data.date.split(" ")[0].replace(/-/g, ".")}
                      </span>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          )}
          {filteredList.length > 0 && (
            <div className="pagination_container">{generatePageButtons()}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsContent;
