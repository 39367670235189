// NewsBanner.jsx
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowSvg } from "../../assets/images/푸터_패밀리사이트_화살표.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInterval from "../../hooks/useInterval"; // 커스텀 훅 (반복 실행)
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../../store/newsSlice";
import SkeletonNewsBanner from "../SkeletonUI/SkeletonNewsBanner";
import useWindowWidth from "../../hooks/useWindowWidth";
import { getAnalytics, logEvent } from "firebase/analytics";

function NewsBanner() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [currentSlide, setCurrentSlide] = useState(1);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [intervalTrigger, setIntervalTrigger] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const dragStartX = useRef(0);
  const dragCurrentX = useRef(0);
  const dragDeltaX = useRef(0);
  const slideContainerRef = useRef(null);

  const dragThreshold = 50;
  const viewWidth = useWindowWidth(0);

  const { newsList, status } = useSelector((state) => state.news);
  const totalSlides = Math.min(3, newsList.length);

  const slideImages = [
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/91da8915-b57e-4902-a6d0-bc6724a80700/news",
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/7fa068e9-ab3a-45f6-c247-d8857fbb4b00/news",
    "https://imagedelivery.net/4aEUbX05h6IovGOQjgkfSw/5ff90474-5f7d-416c-d617-34a4e92ae700/news",
  ];

  const analytics = getAnalytics();

  useEffect(() => {
    if (viewWidth <= 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [viewWidth]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchNews());
    }
  }, [status, dispatch]);

  useInterval(() => handleNextSlide(), 5000, [intervalTrigger]);

  useEffect(() => {
    if (currentSlide > totalSlides + 1 || currentSlide < 0) {
      setCurrentSlide(1);
    }
  }, [currentSlide, totalSlides]);

  useEffect(() => {
    const slideContainer = slideContainerRef.current;
    if (!slideContainer) return;

    slideContainer.style.transition = isTransitioning
      ? "transform 0.5s ease-in-out"
      : "none";

    slideContainer.style.transform = `translateX(-${currentSlide * 100}%)`;

    const handleTransitionEnd = () => {
      if (currentSlide === totalSlides + 1) {
        requestAnimationFrame(() => {
          slideContainer.style.transition = "none";
          setCurrentSlide(1);
          slideContainer.style.transform = `translateX(-100%)`;
          setIsTransitioning(false);
          requestAnimationFrame(() => {
            slideContainer.style.transition = "transform 0.5s ease-in-out";
          });
        });
      } else if (currentSlide === 0) {
        requestAnimationFrame(() => {
          slideContainer.style.transition = "none";
          setCurrentSlide(totalSlides);
          slideContainer.style.transform = `translateX(-${totalSlides * 100}%)`;
          setIsTransitioning(false);
          requestAnimationFrame(() => {
            slideContainer.style.transition = "transform 0.5s ease-in-out";
          });
        });
      } else {
        setIsTransitioning(false);
      }
    };

    slideContainer.addEventListener("transitionend", handleTransitionEnd);
    return () => {
      slideContainer.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [currentSlide, totalSlides, isTransitioning]);

  if (status === "loading") {
    return <SkeletonNewsBanner />;
  }

  if (totalSlides === 0) {
    return null;
  }

  const goToSlide = (index) => {
    if (isTransitioning) return;
    setCurrentSlide(index);
    setIsTransitioning(true);
  };

  const handleNextSlide = () => {
    logEvent(analytics, "news_banner_next_btn", {
      content_type: "Button",
    });
    goToSlide(currentSlide + 1);
    setIntervalTrigger((prev) => prev + 1);
  };

  const handlePrevSlide = () => {
    logEvent(analytics, "news_banner_prev_btn", {
      content_type: "Button",
    });
    goToSlide(currentSlide - 1);
    setIntervalTrigger((prev) => prev + 1);
  };

  const handleDragStart = (clientX) => {
    if (isTransitioning) return;
    logEvent(analytics, "news_banner_drag", {
      content_type: "Drag",
    });
    setIsDragging(true);
    dragStartX.current = clientX;
  };

  const handleDragMove = (clientX) => {
    if (!isDragging) return;
    dragCurrentX.current = clientX;
    dragDeltaX.current = dragCurrentX.current - dragStartX.current;

    const slideContainer = slideContainerRef.current;
    if (slideContainer) {
      const containerWidth = slideContainer.offsetWidth;
      const deltaPercent = (dragDeltaX.current / containerWidth) * 100;
      slideContainer.style.transition = "none";
      slideContainer.style.transform = `translateX(-${
        currentSlide * 100 - deltaPercent
      }%)`;
    }
  };

  const handleDragEnd = () => {
    if (!isDragging) return;
    setIsDragging(false);
    const delta = dragDeltaX.current;

    if (Math.abs(delta) > dragThreshold) {
      if (delta < 0) {
        handleNextSlide();
      } else {
        handlePrevSlide();
      }
    } else {
      const slideContainer = slideContainerRef.current;
      if (slideContainer) {
        slideContainer.style.transition = "transform 0.5s ease-in-out";
        slideContainer.style.transform = `translateX(-${currentSlide * 100}%)`;
      }
    }
    dragStartX.current = 0;
    dragCurrentX.current = 0;
    dragDeltaX.current = 0;
  };

  const onMouseDown = (e) => {
    e.preventDefault();
    handleDragStart(e.clientX);
  };
  const onMouseMove = (e) => handleDragMove(e.clientX);
  const onMouseUp = () => handleDragEnd();
  const onMouseLeave = () => handleDragEnd();

  const onTouchStart = (e) => handleDragStart(e.touches[0].clientX);
  const onTouchMove = (e) => handleDragMove(e.touches[0].clientX);
  const onTouchEnd = () => handleDragEnd();

  const removeBrTags = (text) => text.replace(/<br\s*\/?>/gi, " ");

  const slides = [newsList[totalSlides - 1], ...newsList, newsList[0]];

  const normalizedCurrentSlide =
    currentSlide > totalSlides
      ? 1
      : currentSlide < 1
      ? totalSlides
      : currentSlide;

  return (
    <div
      className="news_banner_wrapper"
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <div
        className={`news_slides_container ${isDragging ? "dragging" : ""}`}
        ref={slideContainerRef}
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
      >
        {/* 왼쪽(첫 슬라이드의 이전) 클론 */}
        <div className="news_slide_item news_clone_last">
          <img
            src={slideImages[totalSlides - 1]}
            alt="News Slide"
            className="news_slide_img"
            loading="lazy"
            decoding="async"
          />
          <span className="news_slide_category">Home · News</span>
          <span className="news_slide_title">
            {t(`news_${slides[0]._id}_title`)}
          </span>
          <span className="news_slide_content">
            {removeBrTags(t(`news_${slides[0]._id}_content`))}
          </span>
          <Link to={`/news/${slides[0]._id}`} className="news_go_news_btn">
            {t("newsTranslation.goToArticle")}
          </Link>
        </div>

        {newsList.map((data, index) => {
          return (
            <div className="news_slide_item" key={data._id}>
              <img
                src={slideImages[index]}
                alt="News Slide"
                className="news_slide_img"
                loading="lazy"
                decoding="async"
              />
              <span className="news_slide_category">Home · News</span>
              <span className="news_slide_title">
                {t(`news_${data._id}_title`)}
              </span>
              <span className="news_slide_content">
                {removeBrTags(t(`news_${data._id}_content`))}
              </span>
              <Link
                to={`/news/${data._id}`}
                className="news_go_news_btn"
                onClick={() => {
                  logEvent(analytics, "news_banner_goToArticle_btn", {
                    content_type: "Button",
                    content_id: data._id,
                  });
                }}
              >
                {t("newsTranslation.goToArticle")}
              </Link>
            </div>
          );
        })}

        {/* 오른쪽(마지막 슬라이드의 다음) 클론 */}
        <div className="news_slide_item news_clone_first">
          <img
            src={slideImages[0]}
            alt="News Slide"
            className="news_slide_img"
            loading="lazy"
            decoding="async"
          />
          <span className="news_slide_category">Home · News</span>
          <span className="news_slide_title">
            {slides[slides.length - 1].title}
          </span>
          <span className="news_slide_content">
            {removeBrTags(slides[slides.length - 1].content)}
          </span>
          <Link
            to={`/news/${slides[slides.length - 1]._id}`}
            className="news_go_news_btn"
            onClick={() => {
              logEvent(analytics, "news_banner_goToArticle_btn", {
                content_type: "Button",
                content_id: slides[slides.length - 1]._id,
              });
            }}
          >
            {t("newsTranslation.goToArticle")}
          </Link>
        </div>
      </div>

      {/* 모바일이면 좌우 버튼 + 진행바, PC면 점(도트) */}
      {!mobile && (
        <>
          <button
            className="news_slide_btn news_prev_slide_btn"
            onClick={handlePrevSlide}
            aria-label="이전 슬라이드로 이동"
            disabled={isTransitioning}
          >
            <ArrowSvg className="news_prev_arrow" />
          </button>
          <button
            className="news_slide_btn news_next_slide_btn"
            onClick={handleNextSlide}
            aria-label="다음 슬라이드로 이동"
            disabled={isTransitioning}
          >
            <ArrowSvg className="news_next_arrow" />
          </button>
        </>
      )}

      {mobile ? (
        <div className="news_progress_bar_wrapper">
          <div className="news_progress_bar_background">
            <div
              className="news_progress_bar_indicator"
              style={{
                left: `${(normalizedCurrentSlide - 1) * (100 / totalSlides)}%`,
                width: `${100 / totalSlides}%`,
              }}
            />
          </div>
        </div>
      ) : (
        <ul className="news_pagination_dots">
          {newsList.map((_, index) => (
            <li
              key={index}
              className={`news_pagination_dot ${
                !isFirstRender &&
                (currentSlide === index + 1 ||
                  (currentSlide === 0 && index === totalSlides - 1) ||
                  (currentSlide === totalSlides + 1 && index === 0))
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                if (isTransitioning) return;
                goToSlide(index + 1);
                setIntervalTrigger((prev) => prev + 1);
              }}
              aria-label={`슬라이드 ${index + 1}`}
            />
          ))}
        </ul>
      )}
    </div>
  );
}

export default NewsBanner;
