export const company_i18n = {
  ko: {
    CompanyBanner: {
      FirstLine:
        "리턴플러스는 금융의 미래를 혁신하는 블록체인 기반의 테크핀 에그리게이터(TechFIN Aggregator)로,",
      SecondLine:
        "다양한 분야에서 토큰증권 발행 및 운용을 통해 새로운 금융 패러다임을 선도하고 있습니다.",
    },
    CompanyContents: {
      CoreValues: {
        Mission: {
          Title: "미션",
          ContentFirst: "투명한 상품으로 투자의 혁신을 선도하고",
          ContentAccent: "누구나 쉽게 접근할 수 있는 유동적 시장",
          ContentSecond: "을 창출합니다",
        },
        Vision: {
          Title: "비전",
          ContentFirst: "블록체인 기술로 투자의 미래를 선도하는",
        },
        Slogan: {
          Title: "슬로건",
          ContentAccent: "혁신적인 STO전문가로서 ",
          ContentFirst: "빠르게 변화하는 금융시장을 선도합니다",
          SvgList: {
            Transparent: {
              Content: "투명한 솔루션",
            },
            Safety: {
              Content: "안전한 자산 보장",
            },
            Community: {
              Content: "고객과의 지속적인 소통",
            },
          },
        },
      },
      Patent: '수상 · 인증',
      History: {
        SubTitle: "회사 연혁",
        2023: {
          '12': [
            "지도정보에 기반한 토지 통합정보 시스템 특허 등록.",
            "블록체인 기반 NFT를 이용한 실물 자산의 디지털 자산화 시스템 특허 출원.",
          ],
        },
        2024: {
          '02': [
            "주식회사 리턴플러스 법인 설립.",
            "벤처기업 인증.",
            "지도 정보에 기반한 토지 정보 제공 서비스 개발.",
          ],
          '03': [
            "사용자 설문 기반의 금융 상품 추천 개발 착수.",
            "금융 정보 (토지 정보) 에 기반한 계산기 서비스 개발.",
          ],
          '04': [
            "지갑 관련 플랫폼 서비스 계약 수주.",
            "한조각 STO 플랫폼 개발 착수.",
            "기업부설연구소 설립 및 디지털 자산 연구 개발.",
          ],
        },
        ShowMore: "펼치기",
        ShowLess: "접기",
      },
      IRDownload: {
        SubTitle: "아래 기입란을 입력하고 회사소개서를 확인하세요.",
        Form: {
          name: "이름",
          industry: "산업군",
          industrySelectBox: [
            "금융/핀테크",
            "제조/건설/에너지",
            "소비/유통",
            "IT/소프트웨어",
            "정부/공공기관",
            "미디어/콘텐츠/엔터테이먼트",
            "물류/항공",
            "의료/헬스케어",
            "마케팅/광고",
            "기타",
          ],
          email: "이메일 주소",
          domainSelectBox: ["naver.com", "google.com", "daum.net", "직접입력"],

          phone: "휴대폰 번호",
          placeholder: {
            input: "입력해주세요",
            category: "카테고리를 선택해주세요",
            phone: "01012345678",
          },
          privacyPolicy: {
            Title: "(필수) 개인정보 수집·이용동의에 대한 안내",
            SubTitle: "개인정보 수집·이용에 대한 안내",
            ContentTitle:
              "(주)리턴플러스는 이용자 문의를 처리하기 위해 다음과 같이 개인정보를 수집 및 이용하며, 이용자의 개인정보를 안전하게 취급하는데 최선을 다하고 있습니다.",
            Grid: {
              CollectItem: "수집항목",
              CollectItemContent: "이메일 주소, 휴대폰 번호",
              CollectPurpose: "수집목적",
              CollectPurposeContent: "문의·요청·불편사항 확인 및 처리결과 회신",
              CollectTerm: "보유기간",
              CollectTermContent: "3년간 보관 후 지체없이 파기",
            },
            Warning:
              "위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 문의처리 및 결과 회신이 제한됩니다. 요구하지 않은 개인정보는 입력하지 않도록 주의해주세요.",
          },
          Button: "다운로드",
          Alert: {
            name: "이름을 입력해주세요.",
            industry: "산업군을 선택해 주세요.",
            emailId: "이메일 아이디를 입력해주세요.",
            phone: "휴대폰번호를 입력해주세요.",
            agree1: "개인정보 수집 및 이용에 동의해주세요.",
            customDomain: "도메인을 입력해 주세요.",
            emailDomain: "도메인을 선택해주세요.",
            SuccessResultMessage:
              "담당자가 확인하여 IR자료와 함께 연락드리겠습니다.",
            FailResultMessage:
              "지원되지않는 브라우저입니다. Chrome으로 접속해주세요.",
          },
        },
      },
    },
  },
  en: {
    CompanyBanner: {
      FirstLine:
        "Return Plus is a blockchain-based TechFIN Aggregator that innovates the future of finance.",
      SecondLine:
        "We lead a new financial paradigm through the issuance and operation of tokenized securities in various fields.",
    },
    CompanyContents: {
      CoreValues: {
        Mission: {
          Title: "Mission",
          ContentFirst:
            "Lead innovation in investment with transparent products,",
          ContentAccent: "creating a liquid market accessible to everyone.",
          ContentSecond: "",
        },
        Vision: {
          Title: "Vision",
          ContentFirst:
            "Leading the future of investment with blockchain technology.",
        },
        Slogan: {
          Title: "Slogan",
          ContentAccent: "Innovative STO Expert,",
          ContentFirst: "leading the rapidly changing financial market.",
          SvgList: {
            Transparent: {
              Content: "Transparent Solutions",
            },
            Safety: {
              Content: "Safe Asset Guarantee",
            },
            Community: {
              Content: "connected with customers",
            },
          },
        },
      },
      Patent: 'Awards · Certs',
      History: {
        SubTitle: "Timeline",
        2023: {
          '12': [
            "Patenting an integrated land information system based on map information.",
            "Patent application for digital assetization system of real assets using blockchain-based NFT.",
          ],
        },
        2024: {
          '02': [
            "Incorporate Returnplus Corporation.",
            "Venture certification.",
            "Developing land information services based on map information.",
          ],
          '03': [
            "Began development of financial product recommendations based on user surveys.",
            "Developing a calculator service based on financial information (land information).",
          ],
          '04': [
            "Winning contracts for wallet-related platform services.",
            "Kickstarting the development of the Piece STO platform.",
            "Establishment of a corporate research center and digital asset research and development.",
          ],
        },
        ShowMore: "ShowMore",
        ShowLess: "ShowLess",
      },
      IRDownload: {
        SubTitle:
          "Please fill in the fields below to view the company introduction.",
        Form: {
          name: "Name",
          industry: "Industry",
          industrySelectBox: [
            "Finance/Fintech",
            "Manufacturing/Construction/Energy",
            "Consumer/Retail",
            "IT/Software",
            "Government/Public Sector",
            "Media/Content/Entertainment",
            "Logistics/Airline",
            "Healthcare",
            "Marketing/Advertising",
            "Others",
          ],
          email: "Email Address",
          domainSelectBox: [
            "naver.com",
            "google.com",
            "daum.net",
            "Enter Email Domain",
          ],
          phone: "Mobile Number",
          placeholder: {
            input: "Please enter",
            category: "Please select a category",
            phone: "01012345678",
          },
          privacyPolicy: {
            Title:
              "(Required) Notice on Consent to Collect and Use Personal Information",
            SubTitle: "Notice on Collection and Use of Personal Information",
            ContentTitle:
              "Return Plus Co., Ltd. collects and uses personal information as follows to process user inquiries, and strives to handle users' personal information safely.",
            Grid: {
              CollectItem: "Collected Items",
              CollectItemContent: "Email address, mobile number",
              CollectPurpose: "Purpose of Collection",
              CollectPurposeContent:
                "To confirm and respond to inquiries, requests, and complaints",
              CollectTerm: "Retention Period",
              CollectTermContent:
                "Retained for 3 years and then promptly destroyed",
            },
            Warning:
              "You have the right to refuse consent, and if you refuse, your inquiry processing and response may be limited. Please be careful not to enter unnecessary personal information.",
          },
          Button: "Download",
          Alert: {
            name: "Please enter your name.",
            industry: "Please select an industry.",
            emailId: "Please enter your email ID.",
            phone: "Please enter your mobile number.",
            agree1:
              "Please agree to the collection and use of personal information.",
            customDomain: "Please enter the domain.",
            emailDomain: "Please select a domain.",
            SuccessResultMessage:
              "The person in charge will contact you with the IR materials after confirmation.",
            FailResultMessage:
              "This browser is not supported. Please use Chrome.",
          },
        },
      },
    },
  },
};
